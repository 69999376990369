import ReactPaginate from 'react-paginate';
import './Pagination.sass';

const Pagination = ( {
  pageCount,
  onPageChange,
  perPage,
  setPerPage,
  perPageVariants,
  forcePage,
  type,
} ) => {
  return (
    <div className={[ 'pagination-box', type ].join( ' ' )}>
      <ReactPaginate
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        previousLabel={'previous'}
        nextLabel={'next'}
        containerClassName={'pagination'}
        pageLinkClassName={'page-link'}
        activeClassName={'active'}
        breakLabel={'...'}
        forcePage={forcePage}
      />
      {perPageVariants && !!perPageVariants.length && (
        <div className='rows-options'>
          <span className='mr-2'>Rows per page</span>
          <div className='group'>
            {perPageVariants &&
              perPageVariants.map( variant => (
                <button
                  key={variant}
                  className={`btn-badge ${perPage === variant && 'active'}`}
                  onClick={() => setPerPage( variant )}
                >
                  {variant}
                </button>
              ) )}
          </div>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {};

export default Pagination;
