import { createSlice } from '@reduxjs/toolkit';

export const createConversationSlice = createSlice( {
  initialState: {
    messageToReply: undefined,
    employeeToReply: undefined,
    joblinkGroupId: undefined,
  },
  name: 'createConversation',
  reducers: {
    setMessageToReply: ( state, action ) => {
      state.messageToReply = action.payload;
    },
    setEmployeeToReply: ( state, action ) => {
      state.employeeToReply = action.payload;
    },
    setJoblinkGroupId: ( state, action ) => {
      state.joblinkGroupId = action.payload;
    },
    clear: state => {
      state.employeeToReply = undefined;
      state.joblinkGroupId = undefined;
      state.messageToReply = undefined;
    },
  },
} );

export const createConversationActions = createConversationSlice.actions;
