import { mainApi } from './mainApi';

export const chatApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getJoblinkGroups: build.query( {
      query: () => ( {
        url: '/joblinkgroups',
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'joblinkGroups' ] ),
    } ),
    getConversationsList: build.query( {
      query: () => ( {
        url: '/conversations',
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ { type: 'conversations', id: 'all' } ] ),
    } ),
    getSingleConversations: build.query( {
      query: id => ( {
        url: `/conversations/${id}`,
      } ),
      providesTags: ( res, err, id ) => ( err ? [] : [ { type: 'conversations', id } ] ),
    } ),
    sendMessage: build.mutation( {
      query: ( { id, message } ) => ( {
        url: `/conversations/${id}/messages`,
        method: 'POST',
        body: message,
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err
          ? []
          : [
              { type: 'conversations', id: arg.id },
              { type: 'conversations', id: 'all' },
            ],
    } ),
    createConversation: build.mutation( {
      query: data => ( {
        url: '/conversations',
        method: 'POST',
        body: data,
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ { type: 'conversations', id: 'all' } ] ),
    } ),
    changeEmpoloyees: build.mutation( {
      query: ( { id, employees } ) => {
        return {
          url: `/conversations/${id}/employees`,
          method: 'PUT',
          body: { employees: employees },
        };
      },
      invalidatesTags: ( res, err, id ) =>
        err
          ? []
          : [
              { type: 'conversations', id },
              { type: 'conversations', id: 'all' },
            ],
    } ),
    updateConversation: build.mutation( {
      query: ( { id, fields } ) => ( {
        url: `/conversations/${id}`,
        body: fields,
        method: 'put',
      } ),
      invalidatesTags: ( res, err, { id } ) =>
        err
          ? []
          : [
              { type: 'conversations', id },
              { type: 'conversations', id: 'all' },
            ],
    } ),
    deleteConversation: build.mutation( {
      query: id => ( {
        url: `/conversations/${id}`,
        method: 'delete',
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ { type: 'conversations', id: 'all' } ] ),
    } ),
    readMessage: build.mutation( {
      query: ( { conversationId, messageId } ) => ( {
        url: `/conversations/${conversationId}/messages/${messageId}/read`,
        method: 'post',
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ { type: 'conversations', id: 'all' } ] ),
    } ),
  } ),
} );

export const {
  useGetJoblinkGroupsQuery,
  useGetConversationsListQuery,
  useGetSingleConversationsQuery,
  useSendMessageMutation,
  useCreateConversationMutation,
  useChangeEmpoloyeesMutation,
  useUpdateConversationMutation,
  useDeleteConversationMutation,
  useReadMessageMutation,
} = chatApi;
