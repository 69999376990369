import { combineReducers } from 'redux';
import { mainApi } from '../apis/mainApi';
import {
  createConversationSlice,
  eventListingFiltersSlice,
  socketSlice
} from '../slices';
import { appSlice } from '../slices/appSlice';

export const rootReducer = combineReducers( {
  [eventListingFiltersSlice.name]: eventListingFiltersSlice.reducer,
  [createConversationSlice.name]: createConversationSlice.reducer,
  [socketSlice.name]: socketSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [mainApi.reducerPath]: mainApi.reducer,
} );
