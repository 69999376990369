import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortBy: 'startTime',
  sortOrder: 'asc',
  costCenterName: { value: 0, label: 'All Cost centers' },
  statusFilter: { value: 'all', label: 'Show all' },
  statusFilterJobProcessing: { value: 'all', label: 'Show all' },
  startDate: new Date(),
  endDate: null,
  paginationState: undefined,
  processingStartDate: undefined,
  processingEndDate: new Date(),
  processingSortBy: 'startTime',
  processingSortOrder: 'asc'
};

export const eventListingFiltersSlice = createSlice( {
  name: 'filters',
  initialState,
  reducers: {
    setSortBy: ( state, action ) => {
      state.sortBy = action.payload;
    },
    setSortOrder: ( state, action ) => {
      state.sortOrder = action.payload;
    },
    setCostCenterName: ( state, action ) => {
      state.costCenterName = action.payload;
    },
    setStatusFilter: ( state, action ) => {
      state.statusFilter = action.payload;
    },
    setStatusFilterJobProcessing: ( state, action ) => {
      state.statusFilterJobProcessing = action.payload;
    },
    setStartDate: ( state, action ) => {
      state.startDate = action.payload;
    },
    setEndDate: ( state, action ) => {
      state.endDate = action.payload;
    },
    setPaginationState: ( state, action ) => {
      state.paginationState = action.payload;
    },
    setProcessingStartDate: ( state, action ) => {
      state.processingStartDate = action.payload;
    },
    setProcessingEndDate: ( state, action ) => {
      state.processingEndDate = action.payload;
    },
    setProcessingSortBy: ( state, action ) => {
      state.processingSortBy = action.payload;
    },
    setProcessingSortOrder: ( state, action ) => {
      state.processingSortOrder = action.payload;
    }
  }
} );

export const {
  setSortBy,
  setSortOrder,
  setCostCenterName,
  setStatusFilter,
  setStatusFilterJobProcessing,
  setStartDate,
  setEndDate
} = eventListingFiltersSlice.actions;

export const eventListingActions = eventListingFiltersSlice.actions;
