import axios from 'axios';
import Cookies from 'js-cookie';
import { store } from '..';
import { _baseURL } from '../../constants';
import { appActions } from '../slices/appSlice';
import { mainApi } from './mainApi';

export const authApi = {
  signIn,
  logout,
  resetPasswordRequest,
  resetPasswordConfirm,
};

const client = axios.create( { baseURL: _baseURL } );

async function signIn( { email, password } ) {
  try {
    const response = await client.post( '/signIn/customerUser', { email, password } );
    const accessToken = response.data?.token;
    if ( !accessToken ) {
      return false;
    }
    Cookies.set( 'cu_token', accessToken );
    store.dispatch( appActions.setAuth( true ) );
    store.dispatch( appActions.setValidated( true ) );
    return true;
  } catch {
    return false;
  }
}

async function logout() {
  store.dispatch( mainApi.util.resetApiState() );
  store.dispatch( appActions.setAuth( false ) );
  Cookies.remove( 'cu_token' );
}

async function resetPasswordRequest( email ) {
  try {
    const response = await client.post(
      '/reset-password/customerUser/request',
      { email },
      {
        headers: {
          frontendurl: `${window.location.origin}/reset-password?token=`,
        },
      }
    );
    return response.status === 200;
  } catch {
    return false;
  }
}

async function resetPasswordConfirm( { token, password } ) {
  try {
    const response = await client.post( '/reset-password/customerUser/confirm', {
      token,
      newPassword: password,
    } );
    return response.status === 200;
  } catch {
    return false;
  }
}
