import { mainApi } from './mainApi';

export const invitationApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getPendingInvites: build.query( {
      query: () => ( {
        url: '/invitation/customerUsers'
      } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'invitedCustomerUsers' ] )
    } ),
    inviteCustomerUser: build.mutation( {
      query: fields => ( {
        url: '/invitation/customerUser',
        method: 'post',
        body: fields,
        headers: {
          frontendurl: `${window.location.origin}/register?token=`
        }
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'invitedCustomerUsers' ] )
    } )
  } )
} );

export const { useGetPendingInvitesQuery, useInviteCustomerUserMutation } = invitationApi;
